import moment from "moment";
import img1 from './img/news-pic/img-1.jpg'
import img2 from './img/news-pic/img-2.jpg'
import img3 from './img/news-pic/img-3.jpg'
import img4 from './img/news-pic/img-4.jpg'
import img5 from './img/news-pic/img-5.jpg'
import img6 from './img/news-pic/img-6.jpg'
import img7 from './img/news-pic/img-7.jpg'
export const NewsData=[
    {   id:1,
        title:"Tigray Workshop Tackles Cochineal Invasion on Beles Plants",
        shortDescription:" On December 12, 2024, experts gathered at the Northern Star Hotel, Mekelle, for a pivotal workshop addressing the “Cochineal Invasion of Beles and Intervention Strategies in Tigray.” Hosted by Adigrat University Beles Institute and sponsored by Beles Tech in partnership with the Tigray Bureau of Agriculture and Natural Resources, the event focused on mitigating the devastating impact of cochineal insects on cactus pear (Beles), a critical resource for the region.",
        fullDescription:"",
        images:[img1,img2,img3,img4,img5],
        date:"12/12/2024"
    },
    {   id:2,
        title:"Belestech and DAA Partner to Empower Youth through Innovation",
        shortDescription:"Belestech and the Development Association of Agame (DAA) have signed a Memorandum of Understanding (MoU) focused on creating job opportunities for young people. The agreement, signed by Dr. Tsegay Tesfay (GM of Belestech) and Memhir Mesfin (DAA Board Chairman), aims to ensure sustainable income for youth through capacity building, innovation, and the provision of technology. This partnership underscores a shared commitment to fostering economic growth and empowering the next generation.",
        fullDescription:"",
        images:[img6,img7],
        date:"December 21, 2024"
    }
]