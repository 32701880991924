import React, { useEffect, useState,useRef } from 'react';
import tesgaytesfay from './img/abebe.jpg';
import alula from './img/alula_gebresas.jpg'
import teweldeImage from './img/mariam.jpg';
import yusuf from './img/yesuf.jpg';
import kiros from './img/kiros.jpg';
import yemane from './img/yemane.jpg';
import kalayu from './img/kalayu.jpg';
import micahel from './img/michael.jpg';
import './style/About.css';
import moment from 'moment';
import backgroundImage from './img/logo.png';
import { getAllCareer } from './api/api_functions';

const teamMembers = [
  {
    name: 'Yusuf Reja',
    role: 'Board Chairman',
    image: yusuf,
  },
  {
    name: 'Dr. Tsegay Tesfay MEZGEBE',
    role: 'General manager, and engineering & technology researcher',
    image: tesgaytesfay,
  },
  
  {
    name: 'Tewelde Adhanom',
    role: 'Deputy general manager, and monitoring & evaluation researcher',
    image: teweldeImage,
  },
  {
    name: 'Dr. Kiros Meles',
    role: 'Researcher,Argonomist',
    image: kiros,
  },
  {
    name: 'Dr. Kalyu Mekonnen',
    role: 'Researcher,Eng`g & Tech',
    image: kalayu,
  },
  {
    name: 'Dr. Alula Gebresas Gerezgiher',
    role: 'Material science and fiber technology researcher',
    image: alula,
  },
  {
    name: 'Dr. Michael Gebreyesus',
    role: 'Researcher,Energy',
    image: micahel,
  },
  {
    name: 'Dr. Yemane Kahasy',
    role: 'Researcher,Cactus pear',
    image: yemane,
  },

];

const HeroSection = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '30%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 100%',
    color: '#ffffff',
  };

  return (
    <section className="hero-section" style={sectionStyle}>
      <div className="container">
        <div className="row">
          {/* Additional content can go here */}
        </div>
      </div>
    </section>
  );
};



const AboutUs = () => {
  const [isopen,setopen]=useState(false);
  const aboutusref= useRef();
  const [Career,setCareer]=useState(null);
  useEffect(()=>{
    aboutusref.current.scrollIntoView({behavior:'smooth'})
    getAllCareer().then((res)=>{
      
      setCareer(res.data)
    })
  },[])
  
  return (
    <div  ref={aboutusref}>
      <HeroSection />
      <main className="py-5">
        <div className="container-fluid p-lg-3">
          {/* About Us Section */}
          <div className="row gx-4 align-items-center justify-content-between">
            <div className="col-md-6 order-2 order-md-1  px-4 px-md-3">
              <div className="mt-5 mt-md-0 px-lg-5 px-sm-3 ">
                <span className="text-muted">Our Story</span>
                <h2 className="display-5 fw-bold">About Us</h2>
                <p className="lead mb-4">
                Beles Technologies is a pioneering initiative established with a transformative vision: to propel cactus pear farming and utilization in Ethiopia’s Tigray region into a model of sustainable development and economic growth. Rooted in a commitment to innovation and environmental stewardship, we aim to harness the underutilized potential of cactus pear, turning it into a key resource that can drive economic advancement, enhance livelihoods, and support ecological sustainability.
                </p>
                <div className="collapse mt-3" id="aboutUsCollapse">
                  <div className=" mt-2 w-100 ">
                    <p className='lead text-justify text-start'>Our journey began with a recognition of the immense opportunities presented by Tigray’s natural cactus pear resources. Yet, we also saw the challenges—limited knowledge of cactus pear farming, lack of modern techniques, and few avenues for its diverse applications in the market. Beles Technologies was founded to bridge these gaps by combining cutting-edge research with sustainable farming practices. Our goal is to support farmers, investors, and local communities in developing cactus pear-based industries that cater to various sectors, including food, animal feed, textiles, renewable energy, and innovative materials such as vegan leather.</p>
                    <p className='lead text-justify text-start'>At the core of our work is an emphasis on Research and Development. We conduct extensive research on cactus pear genetics, sustainable farming techniques, and pest and disease management, enabling small-scale and commercial farmers to increase productivity and resilience. Through our demonstration farms, we showcase efficient farming practices, helping farmers learn and adopt methods that improve crop yields and align with environmental goals.</p>
                    <p className='lead text-justify text-start'>Beles Technologies also emphasizes Capacity Building and Training for farmers, investors, and policymakers. We organize workshops and training programs to disseminate best practices in cactus pear cultivation, product development, and market strategies, ensuring that local communities are equipped to benefit from cactus pear as a viable and profitable resource.</p>
                    <p className='lead text-justify text-start'>Our commitment to Commercialization and Investment Promotion further supports this mission by facilitating investment opportunities in cactus pear-based industries and expanding market channels for cactus pear-derived products. Through strategic partnerships with local and international research institutions, universities, and government bodies, we drive collaboration and resource-sharing initiatives that amplify the impact of our work.</p>
                    <p className='lead text-justify text-start'>In alignment with our focus on sustainable development, Beles Technologies actively engages in Policy Advocacy and Support to foster a conducive environment for the cactus pear industry. We collaborate with policymakers to shape data-driven policies, providing insights that help guide government actions toward the sustainable growth of this sector.</p>
                    <p className='lead text-justify text-start'>Our vision is bold yet grounded in tangible outcomes: to transform Tigray into a global leader in cactus pear farming and utilization, creating sustainable growth that reverberates across communities, industries, and ecosystems. Beles Technologies is more than an organization—it’s a movement dedicated to unlocking the full potential of cactus pear for a brighter, greener future.</p>

                  </div>
                </div>
                <button 
                  className="btn btn-outline-primary" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#aboutUsCollapse" 
                  aria-expanded="false" 
                  aria-controls="aboutUsCollapse"
                  onClick={()=>setopen(!isopen)}
                >
                  {isopen? "Read Less":"Read more"}
              
                </button>
                
              </div>
            </div>

            <div className="col-md-4 mx-auto">
              <div className="my-7 my-lg-0 p-4">
                <h2 className="display-6 fw-bold mb-3">Endless Opportunities Await</h2>
                <p className="lead mb-0">
                  With a commitment to innovation and community, we explore the vast potential of cactus pear farming to provide solutions that are not only effective but also sustainable. Join us on this exciting journey!
                </p>
              </div>
            </div>
          </div>

          {/* Core Values Section */}
          <section className="py-5 integrity-section">
            <div className="container">
              <div className="row justify-content-center text-center mb-4 mb-md-5">
                <div className="col-xl-9 col-xxl-8">
                  <h2 className="display-5 fw-bold">Our Core Values</h2>
                  <p className="lead">
                    At Beles Tech Developments, our values shape our actions and guide our decision-making process, ensuring we remain committed to integrity, innovation, sustainability, collaboration, and community empowerment.
                  </p>
                </div>
              </div>

              {/* Integrity */}
              <div className="row g-0 align-items-center mb-4">
                <div className="col-md-6">
                  <img alt="Integrity" className="img-fluid" src={require('./img/Integrity.jpg')} />
                </div>
                <div className="col-md-6">
                  <div className="content-wrapper p-md-3 p-xl-5 mt-4 mt-md-0">
                    <h2 className="fw-semibold my-1">Integrity</h2>
                    <div className="text-muted">10.10.2023</div>
                    <p className="my-4">We uphold honesty and transparency in all our interactions, ensuring trust and accountability in every project.</p>
                    <a className="btn btn-primary" href="/contact">Learn More</a>
                  </div>
                </div>
              </div>

              {/* Innovation */}
              <div className="row g-0 align-items-center mb-4">
                <div className="col-md-6 order-2 order-md-1">
                  <div className="row justify-content-end">
                    <div className="col-lg-12 col-xxl-10 col-xl-12">
                      <div className="content-wrapper p-md-3 p-xl-5 mt-4 mt-md-0">
                        <h2 className="fw-semibold my-1">Innovation</h2>
                        <div className="text-muted">10.10.2023</div>
                        <p className="my-4">We embrace creativity and technology to develop cutting-edge solutions that drive progress and efficiency.</p>
                        <a className="btn btn-primary" href="/contact">Learn More</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img alt="Innovation" className="img-fluid" src={require('./img/Innovation.jpg')} />
                </div>
              </div>

              {/* Sustainability */}
              <div className="row g-0 align-items-center mb-4">
                <div className="col-md-6">
                  <img alt="Sustainability" className="img-fluid" src={require('./img/Sustainability.jpg')} />
                </div>
                <div className="col-md-6">
                  <div className="content-wrapper p-md-3 p-xl-5 mt-4 mt-md-0">
                    <h2 className="fw-semibold my-1">Sustainability</h2>
                    <div className="text-muted">10.10.2023</div>
                    <p className="my-4">We are committed to environmentally friendly practices, ensuring our developments contribute positively to the planet.</p>
                    <a className="btn btn-primary" href="/contact">Learn More</a>
                  </div>
                </div>
              </div>

              {/* Collaboration */}
              <div className="row g-0 align-items-center mb-4">
                <div className="col-md-6 order-2 order-md-1">
                  <div className="row justify-content-end">
                    <div className="col-lg-12 col-xxl-10 col-xl-12">
                      <div className="content-wrapper p-md-3 p-xl-5 mt-4 mt-md-0">
                        <h2 className="fw-semibold my-1">Collaboration</h2>
                        <div className="text-muted">10.10.2023</div>
                        <p className="my-4">We believe in the power of teamwork, forging partnerships that leverage diverse strengths for mutual benefit.</p>
                        <a className="btn btn-primary" href="/contact">Learn More</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img alt="Collaboration" className="img-fluid" src={require('./img/Collaboration.jpg')} />
                </div>
              </div>

              {/* Community Empowerment */}
              <div className="row g-0 align-items-center mb-4">
                <div className="col-md-6">
                  <img alt="Community Empowerment" className="img-fluid" src={require('./img/Community.jpg')} />
                </div>
                <div className="col-md-6">
                  <div className="content-wrapper p-md-3 p-xl-5 mt-4 mt-md-0">
                    <h2 className="fw-semibold my-1">Community Empowerment</h2>
                    <div className="text-muted">10.10.2023</div>
                    <p className="my-4">We actively support local communities, helping them thrive through initiatives that foster growth and development.</p>
                    <a className="btn btn-primary" href="/contact">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Team Section */}
          <section className="py-5">
      <div className="container-fluid p-4 unique-container">
        <h2 className="text-center display-4 fw-bold mb-4">Our Team</h2>
        <div className="row justify-content-center">
          {teamMembers.map((member, index) => (
            <div className="col-md-3 mx-auto px-5 d-flex justify-content-center mb-4" key={index}>
              <div className="unique-card">
                <img src={member.image} loading='lazy' className="card-img-top img-fluid" alt={member.name} />
                <div className="card-body text-center">
                  <h5 className="card-title my-1 mt-2">{member.name}</h5>
                  <p className="card-text fw-bold my-1 ">{member.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
          {/* Organizational Structure Section */}
          <section className="py-5">
      <div className="unique-container">
        <h2 className="text-center display-4 fw-bold mb-4">Organizational Structure</h2>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="unique-card text-center">
              <div className="card-body">
                <h5 className="card-title">Our Team Structure</h5>
                <img
                  src="./img/organizational-chart.jpg"
                  alt="Organizational Chart"
                  className="img-fluid unique-structure-image"
                />
                <p className="mt-3">Illustrating the roles and relationships within our organization.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-4 bg-light">
  <div className="container-fluid">
    <h2 className="text-center display-4 fw-bold mb-4">Career Opportunities</h2>
    <div className="row">
    {
            Career&& Career.map((data)=>{
              return(
                <CareerOpportunities carrer={data} />
              )
            })
          }
    </div>
  </div>
</section>
         
        </div>
      </main>
    </div>
  );
};

const CareerOpportunities = ({carrer}) => {

  return (
        <div className="col-md-4 mx-auto col-lg-4 mb-4 p-3" >
          <div className="bg-white rounded p-3 w-100">
            <div className="card-body">
              <h5 className="card-title">{carrer.description}</h5>
              <p className="card-text">{carrer.requirements}</p>
              <p className="card-text text-end small fw-bold">Dead line: {moment(carrer.endDate).format('MMMM Do YYYY')}</p>
              <a href={carrer.link} target='_blank' className="btn btn-primary">Apply Now</a>
            </div>
          </div>
        </div>
    
  );
};

export default AboutUs;
