import React from 'react'
import img_1 from './img/news-pic/img-1.jpg'
import { NewsData } from './newsData'
import moment from 'moment'
function NewsSection() {
  return (
    <div className="container my-5">
        <div className='row'>
         {  NewsData.map((data,index)=>{
            return(
                <div className='col-sm-12 mx-auto shadow col-md-4  my-3 p-3' key={index}>
                <div className='card ' style={{maxWidth:"400px"}}>
                <div id={`demo-${data.id}`} className="carousel slide mb-3" data-bs-ride="carousel">

{/* <!-- Indicators/dots --> */}
<div className="carousel-indicators">
{data.images && data.images.map((img,index)=>(
  <button type="button" data-bs-target={`#demo-${data.id}`}  data-bs-slide-to={index} className={index==0?"active":""}></button>

    ))}
</div>

{/* <!-- The slideshow/carousel --> */}
<div className="carousel-inner">
    {data.images && data.images.map((img)=>(
<div className="carousel-item active">
<img src={img} alt={img} className="d-block" style={{width:"100%", height:"300px",objectFit:"cover"}}/>
</div>
    ))}
  

</div>

{/* <!-- Left and right controls/icons --> */}
<button className="carousel-control-prev" type="button" data-bs-target={`#demo-${data.id}`}  data-bs-slide="prev">
  <span className="carousel-control-prev-icon"></span>
</button>
<button className="carousel-control-next" type="button" data-bs-target={`#demo-${data.id}`} data-bs-slide="next">
  <span className="carousel-control-next-icon"></span>
</button>
</div>
                <div className="card-body">
                        <h4 className="card-title text-start px-2 py-3">{data.title}</h4>
                        <p className="card-text small text-secondary text-justify text-start" style={{fontSize:"14px"}}>{data.shortDescription}</p>
                        <h6 className="card-title text-end px-2 my-3 small text-secondary">{moment(data.date).format('MMMM Do YYYY')}</h6>

                </div>
                    </div>

            </div>
            )
         })}
        
        </div>
    </div>
  )
}

export default NewsSection